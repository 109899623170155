// Skills Icons
import htmlIcon from "./images/html.svg"
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"
import designIcon from "./images/design.svg"
import codeIcon from "./images/code.svg"

// Social Icon
import githubIcon from "./images/github.svg"
import instagramIcon from "./images/instagram.svg"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Candy",
  headerTagline: [
    //Line 1 For Header
    "Uncover patterns and building predictions",
    //Line 2 For Header
    "Using data, algorithm, machine learning",
    //Line 3 For Header
    "and AI techniques",
  ],
  //   Header Paragraph
  headerParagraph:
    "I am studying Operation Research and Information Engineering at Cornell University. I am a data scientist with two years of experience translating terabyte datasets into actionable insights for finance, e-commerce and tech companies. I am passionate about solving complex business problems through data analytics to give measurable recommendations.",

  //Contact Email
  contactEmail: "jd2253@cornell.edu",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "Credit Card Detector", //Project Title - Add Your Project Title Here
      para:
        "Build a credit card detector with logistic regression to correctly identy the fraudulent transactions", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1487837647815-bbc1f30cd0d2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8Njl8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/candyduan1026?tab=repositories",
    },
    {
      title: "MovieLens Rating Prediction", //Project Title - Add Your Project Title Here
      para:
        "Built a recommender system for the MovieLens dataset based on 1 million user reviews using KNN and SVD in python", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://about.fb.com/wp-content/uploads/2020/08/NRP-Publishing_our_Recommendation_Guidelines_banner_FINAL.jpg?w=4001",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/candyduan1026/Matrix-Factorization-for-Movie-Recommendations",
    },
    {
      title: "The Landing Page Optimization for the homedepot.com", //Project Title - Add Your Project Title Here
      para:
        "Optimized the landing page destination for traffic entering HomeDepot.com to maximize conversion, gross demand and interaction rate by identifying the optimal landing page type of purchasing journey with Markov Chain. ", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://store-images.s-microsoft.com/image/apps.52856.9007199267044474.ebc95dc6-e4c2-4b6e-bdcd-e9a7df2b8f0d.ba495067-af56-4a9d-914d-dcf9576a864b?mode=scale&q=90&h=300&w=300",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/candyduan1026?tab=repositories",
    },
    // {
    //   title: "Project Four", //Project Title - Add Your Project Title Here
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
    //   //Project Image - Add Your Project Image Here
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1492171983775-a51717616c0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjB8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
    //   //Project URL - Add Your Project Url Here
    //   url: "http://chetanverma.com/",
    // },
    // {
    //   title: "Project Five", //Project Title - Add Your Project Title Here
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
    //   //Project Image - Add Your Project Image Here
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1534239143101-1b1c627395c5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzZ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
    //   //Project URL - Add Your Project Url Here
    //   url: "http://chetanverma.com/",
    // },
    // {
    //   title: "Project Six", //Project Title - Add Your Project Title Here
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
    //   //Project Image - Add Your Project Image Here
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTAyfHxwYXN0ZWx8ZW58MHx8MHw%3D&auto=format&fit=crop&w=400&q=60",
    //   //Project URL - Add Your Project Url Here
    //   url: "http://chetanverma.com/",
    // },

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "I am studying Operation Research and Information Engineering at Cornell University. I am a data scientist with two years of experience translating terabyte datasets into actionable insights for finance, e-commerce and tech companies. I am passionate about solving complex business problems through data analytics to give measurable recommendations.",
  aboutParaTwo:
    "I have been living in the San Diego for 6 years and enjoy the sunshine and beach in California! Then I moved to New York for graduate school, living in the Ithaca for a year.",
  aboutParaThree:
    "I like surfing and watching movies.",
  aboutImage:
    "https://avatars.githubusercontent.com/u/68354372?v=4",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: htmlIcon,
      para:
        "Python: Pandas, Numpy, Sicket-Learn, Seaborn and Matplotlib",
    },
    {
      img: cssIcon,
      para:
        "Hadoop: MapReduce, Hive, Pig and HDFS",
    },
    {
      img: jsIcon,
      para:
        "SQL",
    },
    {
      img: reactIcon,
      para:
        "Java",
    },
    {
      img: designIcon,
      para:
        "R",
    },
    {
      img: codeIcon,
      para:
        "STATA",
    },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "Work",
  promotionPara:
    "The Home Depot, Goal Structured Solutions, Inc.(GS2), PricewaterhouseCoopers(PwC)",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's go into the data science world together",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: "https://github.com/candyduan1026" },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/",
    },
  ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
